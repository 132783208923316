/*
 * Override default variables before the import
 */
$enable-negative-margins: true;

/* Theme */
$body-bg: #161616;
$body-color: #dedede;
$font-size-base: 0.85rem;

/* Tabs (InfoPanel) */
$nav-tabs-border-color: #505050;
$nav-tabs-link-active-border-color: #505050 #505050 #161616;
$nav-tabs-link-active-color: #8c8c8c;
$nav-link-hover-color: #e9ecef;
$nav-tabs-link-hover-border-color: #505050 #505050 #161616;

//overrides to shrink .me table to fit everything (fairly important)
.me table.table-sm > :not(caption) > * > * {
  @media (min-width: 768px) {
    padding: 0.1rem 0.15rem;
  }
}

/*
 * Extended Helpers
 */
.fs-7 {
  font-size: 0.875rem;
}
.pt-6 {
  padding-top: 4rem;
}
.px-3-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.vh-75 {
  height: 75vh;
}

.rounded-sm {
  border-radius: 0.375rem;
}

@media (max-width: 567px) {
  .rounded-sm {
    border-radius: 0px;
  }
}

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";
